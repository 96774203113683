@keyframes arrow-jump {
  0%   { opacity: 0;}
  100% { opacity: 1; 
        -webkit-transform: translateY(10px);
        -moz-transform: translateY(10px);
        -0-transform: translateY(10px);
        transform: translateY(10px);
    }
}
#arrow {
  -webkit-animation: arrow-jump 3s infinite; /* Safari 4+ */
  -moz-animation:    arrow-jump 3s infinite; /* Fx 5+ */
  -o-animation:      arrow-jump 3s infinite; /* Opera 12+ */
  animation:         arrow-jump 3s infinite; /* IE 10+, Fx 29+ */
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 1268px) {

}

@media only screen and (max-width: 1268px) and (min-width: 900px) {

  div.two-col {
    width: 30%;
  }

  div.three-col {
    width: auto !important;
  }

}

@media only screen and (max-width: 900px) and (min-width: 0px) {
  div.banner {
    width: auto;
  }
  div.two-col {
    width: auto;
    width: 350px;
  }
  div.players {
    margin: 16px auto; 
  }
  h1 {
    margin: auto !important;
    font-size: 1.75em !important;
    padding: 2px !important;
  }
  p {
    font-size: 16px !important;
    margin: 0 !important;
  }

}

* {
  margin: auto;
  padding: 0;
}

td {
  padding-left: 5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 8px;
}

h1 {
  background-color: #acf0ea;
  margin: auto 5rem;
  padding: 8px;
  font-size: 4em;
}

h2 {
  padding: 25px;
  font-size: 3em;
  font-weight: 800;
}

ul {
  margin-top: 0;
}

img {
  padding: 16px;
  margin: 16px;
}

hr.intro {
  background-color: black;
  width: 40%;
  height: 4px;
  border: 2px solid black;
  border-radius: 10px;
}

.two-col {
  width: 40%;
  display: inline-block;
}

.three-col {
  display: inline-block;
  margin: 12px;
  padding: 8px;
  min-height: 230px;
  min-width: 310px;
}

#footer .two-col  p {
  padding: 16px;
  margin: 20px;
  line-height: 15px;
  font-size: 16px;
}

.three-col .player-item{
  width: 25%;
  display: inline-block;
  margin: 12px;
}

.link {
  text-decoration: none;
  border: 5px solid #acf0ea;
  border-radius: 10px;
  background-color: #acf0ea;
  color: #000000;
  font-weight: 600;
}

.link:hover {
  opacity: .75;
}

.player-feature {
  margin-left: 12px;
  padding: 8px 0;
  width: 100%;
  /* min-height:100px;
  min-width: 180px; */
}

.player-image#red {
  border: 4px solid red;
  box-shadow: 2px -3px 20px red;
}

.player-image#yellow {
  border: 4px solid yellow;
  box-shadow: 2px -3px 20px yellow;
}

.player-image#green {
  border: 4px solid green;
  box-shadow: 2px -3px 20px green;
}

.player-image {
  border: 2px solid black;
  border-radius: 10px;
  min-width: 75px;
  background-color: #aaaaaacc;
}

.player-name {
  font-size: 22px;
  font-weight: 600;
  margin: 12px;
}

.analysis {
  margin: 2px;
}

.player-analysis {
  text-align: left;
  margin: 20px;
}

.stat-entry {
  margin: 12px 8px;
  padding: 6px;
  display: inline-block;
}

.stat-entry hr {
  height: 2px;
  width: 50%;
  background-color: #000;
  margin: 4px auto;
}

.stat-entry strong {
  margin: 12px auto;
  padding: 6px;
}

#home {
  min-height: 100vh;
  background-image: url('assets/homepage-splash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
}

#footer {
  margin: 20px;
  padding: 8px;
}

.banner {
  padding: 20px;
  width: 50%;
  position: absolute;
  top: 25vh;
  left: 0;
  right: 0;
}

.banner p {
  padding: 20px;
  font-size: 22px;
  background-color: #acf0ea;
}

.banner hr {
  height: 4px;
  border: 1px solid grey;
  border-radius: 2px;
  background-color: grey;
}

.player-item {
  margin: 12px;
  padding: 6px;
  border: 2px solid grey;
  max-width: 515px;
}

#menu {
  position: fixed;
  left: 0;
  width: 100%;
  text-align: left;
  background-color: grey;
  width: 10%;
  float: left;
  border-left: 3px solid black;
}

#menu span {
  margin: 5px;
  padding: 5px;
}

#github-link {
  background-image: url('assets/github.svg');
  background-color: #acf0ea;
  background-repeat: no-repeat;
  background-position: center;
  height: 35px;
  width: 35px;
  margin: 20px auto;
}

#github-link:hover {
  opacity: 0.75;
  transition: 0.3s;
}

.card {
  width: 100%;
  border-radius: 20px;
  min-height: 310px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: white;
  position: relative;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
}

.featured-content{ background-color: #f1f1f1;}

.players {
  margin: 20px 2em;
}

table.stats {
  padding: 16px;
  margin: 16px auto;
}

.content {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%
}

.rank {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 26px;
  font-weight: 600;
}

.tooltip p {
  font-size: 16px;
  font-weight: 500;
}

.tooltip {
  position: absolute;
  top: -75px;
  right: 0;
  left: 0;
}